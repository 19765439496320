<template>
  <router-link :to="routeLocation" :aria-label="title" @click="onCardClick">
    <VerticalCard
      :img-alt="imageAlt"
      :img="image"
      :badge-text="badgeText"
      :cta-text="ctaText"
      :completed-percentage="completedPercentage"
      :is-completed="isCompleted"
      variant="catalog"
    >
      <template #pre-title>{{ format }}</template>
      <template #title>{{ title }}</template>
      <template #description>{{ description }}</template>
    </VerticalCard>
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import VerticalCard from '../../../../components/VerticalCard/VerticalCard.vue';
import { placeholderLessonImage } from '../../../../lib/lesson';
import itly from '../../../../lib/analytics/itly';
import { EducationResource } from '../../../../api/educationResources';
import { selectedEcosystem } from '../../../Lesson/lessonStore';

export interface ICatalogCardProps {
  format: 'LEARNING PATH' | 'LESSON';
  title: string;
  description: string;
  completedPercentage: number;
  isCompleted: boolean;
  routeLocation: string;
  educationResourceId: string;
  educationContentCategory: EducationResource['attributes']['education_content_category'];
  img?: string;
  category?: string;
  badgeText?: string | null;
  selectedFilters: { [key: string]: string[] };
}
const props = defineProps<ICatalogCardProps>();
const {
  format,
  img,
  completedPercentage,
  isCompleted,
  title,
  description,
  category,
  badgeText,
  educationResourceId,
  routeLocation,
  educationContentCategory,
  selectedFilters,
} = toRefs(props);

const onCardClick = () => {
  if (format.value === 'LESSON') {
    itly.lessonIsClicked({
      lessonId: educationResourceId.value,
      lessonTitle: title.value,
      lessonUrl: routeLocation.value,
      learningType: educationContentCategory.value,
      origin: 'catalog',
    });
  } else if (format.value === 'LEARNING PATH') {
    itly.learningPathIsClicked({
      title: title.value,
      learningType: educationContentCategory.value,
      eventSource: 'Learn',
      origin: 'catalog',
    });
  }
  // If only one category is selected in the catalog filters we want to make sure that this category is also being selected in the lesson.
  // If more than one category is selected in the catalog filters we want to make sure that the first selected category is also being selected in the lesson.
  if (selectedFilters.value && selectedFilters.value['Categories'].length > 0) {
    selectedEcosystem.value = selectedFilters.value['Categories'][0];
  }
};

const image = computed(() => img?.value || placeholderLessonImage);

const imageAlt = computed(() =>
  category?.value ? `${category.value} ${title.value}` : title.value,
);

const ctaText = computed(() => {
  if (isCompleted.value) {
    return 'View';
  } else if (completedPercentage.value > 0) {
    return 'Continue';
  } else {
    return 'Start';
  }
});
</script>
